
import Component from 'vue-class-component';
import { FULL_DATE_TIME } from '@/constants';
import FilterItem from './FilterItem.vue';
import BaseFilter from './BaseFilter';

@Component({ components: { FilterItem } })
export default class RangeFilter extends BaseFilter {
  onChange(value: any) {
    this.notifyChange(value);
  }

  get fullDateTime() {
    return FULL_DATE_TIME;
  }
}
